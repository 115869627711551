import React from 'react'
// import ReactDOM from 'react-dom'
import ReactDOM from "react-dom/client"
import reportWebVitals from './reportWebVitals'
import './styles/index.css'
import App from './app'

// ReactDOM.render(
// 	<React.StrictMode>
// 		<App /> 
// 	</React.StrictMode>,
//   document.getElementById('root')
// )

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)

// padding 80px (for bio)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
